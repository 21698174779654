<template>
  <div style="width: 100%">
    <crud-md :delete-confirm-text="deleteConfirmCrudText"></crud-md>
  </div>
</template>

<script>
export default {
  components: {
    CrudMd: () => import("@/components/crud-md"),
  },
  data: () => ({
    deleteConfirmCrudText: {
      single:
        "Si eliminas esta formación, se eliminará toda la información relacionada.<br /><br /><strong>DOCUMENTOS INCLUIDOS</strong><br /><br /> ¿Quieres eliminar esta formación?",
      multiple:
        "Si eliminas estas formaciones, se eliminará toda la información relacionada.<br /><br /><strong>DOCUMENTOS INCLUIDOS</strong><br /><br /> ¿Quieres eliminar estas formaciones?",
    },
  }),
};
</script>
